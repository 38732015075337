<template>
  <b-card style="zoom: 85%">
    <b-row>
      <b-col class="text-center">
        <h1 class="mt-3">Paket Harga</h1>
        <p class="mb-2 pb-75">
          Beberapa fitur tersedia dibeberapa paket yang ada, ayo mulai
          sekarang.!
        </p>
        <div
          class="d-flex align-items-center justify-content-center mb-5 pb-50"
        >
          <b-form-group
            v-slot="{ ariaDescribedby }"
            label="Pilihan Durasi yang anda inginkan"
          >
            <b-form-radio-group
              v-model="selectedMonth"
              :options="options"
              :aria-describedby="ariaDescribedby"
              button-variant="outline-primary"
              :active="{
                class: 'primary',
              }"
              size="lg"
              name="radio-btn-outline"
              buttons
            />
          </b-form-group>
        </div>
      </b-col>
    </b-row>
    <b-row class="pricing-card">
      <b-col offset-sm-2 sm="12" md="12" offset-lg="2" lg="12" class="mx-auto">
        <b-row class="match-height">
          <b-col
            v-for="(data, index) in dataPricing.data"
            :key="index"
            md="3"
            align-self="stretch"
          >
            <b-card border border-variant="primary" align="center">
              <b-card-body>
                <b-card-text>Paket</b-card-text>
                <h3>{{ data.memberName }}</h3>

                <!-- annual plan -->
                <div class="annual-plan" style="zoom: 95%">
                  <div v-if="data.priceSum !== 0" class="plan-price mt-2">
                    <sup class="font-medium-1 font-weight-bold text-primary"
                      >Rp.
                    </sup>
                    <span
                      class="pricing-basic-value text-price font-bolder text-primary font-"
                    >
                      {{ formatNumber(data.priceSum) }}
                    </span>
                    <p>
                      <sub
                        class="pricing-duration text-body font-medium-1 font-weight-bold"
                        >/ {{ data.month }} bulan</sub
                      >
                    </p>
                  </div>
                  <div v-else-if="data.priceSum === 0" class="plan-price mt-2">
                    <span
                      class="pricing-basic-value text-price font-bolder text-primary font-"
                    >
                      Gratis
                    </span>
                  </div>
                </div>
                <!--/ annual plan -->

                <!-- plan benefit -->
                <b-list-group class="list-group-circle text-left">
                  <b-list-group-item
                    v-for="(feature, idx) in data.features"
                    :key="idx"
                  >
                    {{ feature }} toko
                  </b-list-group-item>
                </b-list-group>
                <!--/ plan benefit -->
              </b-card-body>
              <!-- buttons -->
              <b-card-footer
                v-if="dataPricing.memberType === data.memberName"
                class="d-flex justify-content-end align-items-end"
              >
                <b-button
                  v-if="data.memberName !== 'Pelapak'"
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  block
                  class="mt-2"
                  variant="primary"
                  disabled
                >
                  Telah terdaftar
                </b-button>
              </b-card-footer>
              <b-card-footer
                v-else
                class="d-flex justify-content-end align-items-end"
              >
                <b-button
                  v-if="data.memberName !== 'Pelapak'"
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  block
                  class="mt-2"
                  variant="primary"
                  @click.prevent="LanggananXbulan(data)"
                >
                  Beli Sekarang
                </b-button>
              </b-card-footer>
            </b-card>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  defineComponent,
  reactive,
  ref,
  watch,
  onMounted,
  computed,
} from '@vue/composition-api';
import {
  BFormCheckbox,
  BFormCheckboxGroup,
  BFormGroup,
  BFormRadioGroup,
  BRow,
  BCol,
  BCard,
  BImg,
  BCardText,
  BCardFooter,
  BListGroup,
  BListGroupItem,
  BButton,
  BBadge,
} from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue';
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue';
// eslint-disable-next-line import/no-cycle
import store from '@/store';

/* eslint-disable global-require */
export default defineComponent({
  components: {
    BFormCheckbox,
    BFormCheckboxGroup,
    BFormGroup,
    BFormRadioGroup,
    BButton,
    BCardText,
    BCardFooter,
    BListGroup,
    BListGroupItem,
    BRow,
    BCol,
    BCard,
    BBadge,
    BImg,
    AppCollapseItem,
    AppCollapse,
  },
  directives: {
    Ripple,
  },
  setup() {
    // CHANGE PRICE BY SELECTED MONTH
    const options = reactive([
      { text: '1 Bulan', value: 1 },
      { text: '3 Bulan', value: 3 },
      { text: '6 Bulan', value: 6 },
      { text: '1 Tahun', value: 12 },
    ]);

    const getPackagePriceByMonth = month => {
      store.dispatch('subscribe/getPackagePriceByMonth', month);
    };

    const selectedMonth = ref(1); // Must be an array reference!

    watch(
      () => selectedMonth.value,
      () => {
        getPackagePriceByMonth(selectedMonth.value);
      },
    );
    // END OF CHANGE PRICE BY SELECTED MONTH

    // DATA TO SHOW IN COMPONENT CARD PRICING
    const dataPricing = reactive({
      data: computed(() => store.state.subscribe.allPackage),
      memberType: computed(() => store.state.user.user.member),
    });

    // SNAP MIDTRANS PAYMENT
    const LanggananXbulan = async data => {
      const payload = {
        // eslint-disable-next-line no-underscore-dangle
        packageId: data._id,
        months: selectedMonth.value,
      };
      await store.dispatch('subscribe/getPaymentUrl', payload).then(() => {
        const { mdtToken } = store.state.subscribe;
        if (mdtToken) {
          window.snap.pay(mdtToken, {
            onSuccess(result) {
              console.log(result);
            },
            onPending(result) {
              console.log(result);
            },
            onError(result) {
              console.log(result);
            },
            onClose() {
              console.log(
                'customer closed the popup without finishing payment',
              );
            },
          });
        }
      });
    };

    const midtransSnap = document.createElement('script');
    midtransSnap.src = 'https://app.sandbox.midtrans.com/snap/snap.js';
    midtransSnap.type = 'text/javascript';
    midtransSnap.setAttribute(
      'data-client-key',
      'SB-Mid-client-XwQAgbr7a20eKC8H',
    );

    document.getElementsByTagName('head')[0].appendChild(midtransSnap);
    // END OF SNAP MIDTRANS PAYMENT

    // CHECK AVAILABLE FREE BUTTON
    // END OF CHECK AVAILABLE FREE BUTTON
    const getUserMemberType = () => {
      store.dispatch('user/getUserProfile');
    };

    // CHECK BUY PACKAGE BUTTON

    // END OF CHECK BUY PACKAGE BUTTON

    // FORMAT NUMBER FOR PRICE
    const formatNumber = number =>
      new Intl.NumberFormat('id-ID').format(number);

    onMounted(() => {
      getPackagePriceByMonth(1);
      getUserMemberType();
    });

    return {
      // PricingCard,
      selectedMonth,
      options,
      dataPricing,
      LanggananXbulan,
      formatNumber,
      getUserMemberType,
    };
  },
});
/* eslint-disable global-require */
</script>

<style lang="scss">
.btn-outline-primary:not(:disabled):not(.disabled).active {
  background-color: #2196f3 !important;
  color: white !important;
}
.btn-outline-primary:hover {
  background-color: #2195f39f !important;
  color: white !important;
}
.text-price {
  font-size: 2.85rem !important;
  font-weight: bold;
}
@import '@core/scss/vue/pages/page-pricing.scss';
</style>
